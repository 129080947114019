import { TextField as TextFieldMUI, InputAdornment, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { ScaleIconActionCopyPaste } from '@telekom/scale-components-react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

export const CopyField = ({
  id,
  name,
  value,
  label,
  placeholder,
  autoFocus,
  disabled,
  helperText,
}) => {
  const copyToClipboard = useCopyToClipboard();

  return (
    <TextFieldMUI
      type="text"
      id={id || name}
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={disabled}
      helperText={helperText}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => copyToClipboard(value)}
              edge="end"
            >
              <ScaleIconActionCopyPaste />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

CopyField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

CopyField.defaultProps = {
  id: '',
  placeholder: '',
  autoFocus: false,
  disabled: false,
  helperText: '',
};
