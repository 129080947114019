import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ScaleButton, ScaleIconActionCopyPaste, ScaleModal } from '@telekom/scale-components-react';
import { v4 as uuidv4 } from 'uuid';
import OverviewTable from '../../../components/OverviewTable/OverviewTable';
import { requestFailed, requestFulfilled, requestStarted } from '../../../redux/status/actions';
import Caas from '../../../api/caas';
import CopyButton from '../../../components/CopyButton';
import { enqueueSnackbar } from '../../../redux/notifier/actions';
import { TextField } from '../../../components/InputFields/index';

const CaasCluster = () => {
  const { clusterName } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [cluster, setCluster] = useState(null);
  const [kubeconfig, setKubeconfig] = useState(null);
  const [kubeconfigModalOpened, setKubeconfigModalOpened] = useState(false);

  useEffect(() => {
    const getClusterDetails = async () => {
      try {
        dispatch(requestStarted());
        const response = await Caas.getClusterDetails(uuidv4());
        setCluster(response.data);
        dispatch(requestFulfilled());
      } catch (error) {
        dispatch(requestFailed());
      }
    };
    getClusterDetails();
  }, [clusterName]);

  const handleGetKubeConfig = async () => {
    try {
      dispatch(requestStarted());
      const response = await Caas.getClusterKubeConfig(uuidv4());
      setKubeconfig(response.data.value);
      dispatch(requestFulfilled());
      setKubeconfigModalOpened(true);
    } catch (error) {
      dispatch(requestFailed());
      dispatch(enqueueSnackbar({
        message: 'CaasClusterKubeconfigGetErr',
        options: { variant: 'error' },
      }));
    }
  };

  return cluster ? (
    <Container maxWidth="md">
      <ScaleModal
        opened={kubeconfigModalOpened}
        heading="Kube Config"
        onScale-close={() => setKubeconfigModalOpened(false)}
      >
        <TextField
          label="Kube Config"
          name="KubeConfig"
          value={kubeconfig}
          InputProps={{ readOnly: true }}
        />
        <CopyButton
          data={kubeconfig}
          onSubmit={() => setKubeconfigModalOpened(false)}
        />
      </ScaleModal>
      <OverviewTable
        header={`${cluster.name} Cluster Details`}
        rows={[
          {
            title: 'Owner',
            value: cluster.owner.email,
          },
          {
            title: 'Cloud',
            value: cluster.cloud,
          },
          {
            title: 'Environment',
            value: cluster.environment,
          },
          {
            title: 'Kubernetes Version',
            value: cluster.kubernetesVersion,
          },
          {
            title: 'Kube Config',
            component: (
              <ScaleButton
                variant="secondary"
                size="small"
                disabled={cluster.owner.email !== user.email}
                onClick={handleGetKubeConfig}
              >
                <ScaleIconActionCopyPaste />
                Copy Kube Config
              </ScaleButton>
            ),
          },
          {
            title: 'Groups',
            value: Object.keys(cluster.workerGroups).length,
          },
          {
            title: 'Status',
            value: cluster.status.operationStatus,
          },
        ]}
      />
    </Container>
  ) : null;
};

export default CaasCluster;
