import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';
import { mergeMap, debounceTime, filter } from 'rxjs/operators';
import * as Yup from 'yup';
import {
  Formik,
  Form,
  useFormikContext,
  useField,
} from 'formik';
import {
  ScaleButton,
  ScaleCheckbox,
  ScaleTag,
  ScaleLoadingSpinner,
} from '@telekom/scale-components-react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { regexValidator } from '../../../../helpers/regexValidator';
import Provision from '../../../../api/provision';
import useApiCall from '../../../../hooks/useApiCall';
import Auth from '../../../../api/auth';
import {
  FormikSelect,
  FormikTextField,
} from '../../../../components/Formik/index';

const productTypesLookup = (organization) => (
  organization.marketplaceItems?.reduce((acc, curr) => {
    if (!['Safescarf', 'Evaluation', 'Demands', 'Monitoring'].includes(curr.name)) {
      acc[curr.name] = curr.name;
    }
    return acc;
  }, {}) || {}
);

const NameField = () => {
  const { setFieldValue, initialValues } = useFormikContext();
  return (
    <FormikTextField
      autoFocus
      name="name"
      label="Product Name"
      onChange={(e) => setFieldValue('juiceGroup', `${initialValues.juiceGroup}${e.target.value}`)}
    />
  );
};

const juiceGroupSubject = new BehaviorSubject('');
const juiceGroupObservable = juiceGroupSubject.pipe(
  debounceTime(3000),
  filter((juiceGroup) => juiceGroup !== ''),
  mergeMap(Provision.fetchJuiceGroupByName),
);

const JuiceGroupField = () => {
  const {
    setStatus,
    setFieldError,
    status,
  } = useFormikContext();
  const [field, meta] = useField({ name: 'juiceGroup' });

  useEffect(() => {
    const subscription = juiceGroupObservable.subscribe((data) => {
      const juiceGroupAvailability = data.data?.title;
      if (juiceGroupAvailability !== 'Not Found') {
        setFieldError('juiceGroup', 'Juice Group is unavailable');
      }
      setStatus();
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!meta.error) {
      setStatus('checking juice group');
      juiceGroupSubject.next(field.value);
    }
  }, [field.value, meta.error]);

  return (
    <div style={{
      position: 'relative',
    }}
    >
      <FormikTextField
        name="juiceGroup"
        label="Juice Group"
      />
      {status && (
      <div style={{
        display: 'flex',
        position: 'absolute',
        right: '0.5rem',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      >
        <ScaleLoadingSpinner style={{ margin: '0' }} />
      </div>
      )}
    </div>
  );
};

const Tags = ({
  tags,
  setTags,
}) => {
  const fetchTags = useApiCall(Provision.fetchTags, null, null);
  const [juiceTags, setJuiceTags] = useState([]);

  useEffect(() => {
    async function getTags() {
      const [data] = await fetchTags();
      if (data) setJuiceTags(data);
    }
    getTags();
  }, []);

  return (
    <Grid
      container
      direction="column"
    >
      <Grid item>
        <Typography>
          Juice Group Tags:
        </Typography>
      </Grid>
      <Grid
        item
        display="grid"
        gridTemplateColumns="50% 50%"
      >
        {juiceTags.map((tag) => (
          <ScaleCheckbox
            key={tag}
            style={{ marginTop: '0.5rem' }}
            checked={tags.includes(tag)}
            onScale-change={(event) => {
              if (event.target.checked) {
                setTags([...tags, tag]);
              } else {
                setTags(tags.filter((t) => t !== tag));
              }
            }}
          >
            <ScaleTag type="strong">{tag}</ScaleTag>
          </ScaleCheckbox>
        ))}
      </Grid>
    </Grid>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  setTags: PropTypes.func.isRequired,
};

const CreateProductForm = ({ formRef, onSubmit, onCancel }) => {
  const { projectId } = useParams();
  const [tags, setTags] = useState([]);
  const [initialValues, setInitialValues] = useState();
  const [project, setProject] = useState({});
  const [organization, setOrganization] = useState({});

  const getOrganizationCall = useApiCall(Auth.fetchV1OrganizationById);
  const getProjectCall = useApiCall(Auth.fetchV2ProjectById);
  const createProductCall = useApiCall(Auth.createV2Product);

  async function getProject() {
    const [data] = await getProjectCall(projectId);
    if (data) {
      setProject(data);
      setInitialValues({
        name: '',
        juiceGroup: `${data.organization.enterpriseName}-${data.name}-`,
        description: '',
        type: '',
      });
    }
  }

  useEffect(() => {
    getProject();
  }, []);

  async function getOrganization(id) {
    const [data] = await getOrganizationCall(id);
    if (data) setOrganization(data);
  }

  useEffect(() => {
    if (project.organizationId) {
      getOrganization(project.organizationId);
    }
  }, [project]);

  const createProduct = async (formValues) => {
    const [data, error] = await createProductCall({
      ...formValues,
      tags,
      projectId,
      organizationId: project.organizationId,
    });
    if (error) onCancel();
    if (data) {
      formRef.current.resetForm();
      onSubmit();
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .matches(regexValidator.name, 'All lower-case, no spaces, no special characters (dash only)'),
    juiceGroup: Yup.string()
      .required('Required')
      .matches(regexValidator.juiceGroup, 'The Juice Group must start with a letter and contain only lower-case letters, numbers, and dashes')
      .min(3, 'The Juice Group must be at least 3 characters long')
      .max(64, 'The Juice Group must be at most 64 characters long'),
    description: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
  });

  const productTypes = productTypesLookup(organization);
  const productTypesItems = Object.keys(productTypes).map((key) => ({
    value: key,
    label: productTypes[key],
  }));

  return initialValues && (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={createProduct}
    >
      {({ dirty, isValid, status }) => (
        <Form>
          <Grid
            container
            direction="column"
            pb={2}
          >
            <Grid item>
              <NameField />
            </Grid>
            <Grid item>
              <JuiceGroupField />
            </Grid>
            <Grid item>
              <FormikTextField
                name="description"
                label="Description"
              />
            </Grid>
            <Grid item>
              <FormikSelect
                name="type"
                label="Product Type"
                menuItems={productTypesItems}
              />
            </Grid>
            <Grid item>
              <Tags
                tags={tags}
                setTags={setTags}
              />
            </Grid>
            <Grid
              container
              justifyContent="end"
              gap={1}
            >
              <Grid item>
                <ScaleButton
                  variant="primary"
                  disabled={status || !dirty || !isValid}
                >
                  Create
                </ScaleButton>
              </Grid>
              <Grid item>
                <ScaleButton
                  name="cancel"
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    setTags([]);
                    formRef.current.resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </ScaleButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

CreateProductForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      resetForm: PropTypes.func.isRequired,
    }),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateProductForm;
