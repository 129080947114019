import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Formik,
  Form,
} from 'formik';
import {
  ScaleButton,
  ScaleModal,
} from '@telekom/scale-components-react';
import * as Yup from 'yup';
import validator from 'validator';
import { Grid } from '@mui/material';
import useApiCall from '../../../hooks/useApiCall';

import Auth from '../../../api/auth';
import CopyButton from '../../../components/CopyButton';
import { FormikPasswordField } from '../../../components/Formik';
import { TextField } from '../../../components/InputFields';

const ApiKeyForm = () => {
  const intl = useIntl();
  const [apiKey, setApiKey] = useState(null);
  const createApiKey = useApiCall(Auth.createApiKey, null, 'apiKeyGenerationErr');

  const validationSchema = Yup.object({
    password: Yup.string()
      .test(
        'Strong password',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
  });

  const handleSubmit = async ({ password }) => {
    const [data] = await createApiKey(password);
    if (data) setApiKey(data.apiKey);
  };

  return (
    <>
      {apiKey && (
        <ScaleModal
          opened
          size="small"
          heading="Your API key is ready"
          onScale-close={() => setApiKey(null)}
        >
          <TextField
            label="API key"
            name="APIkey"
            value={apiKey}
            InputProps={{ readOnly: true }}
          />
          <CopyButton data={apiKey} />
        </ScaleModal>
      )}
      <Formik
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid, isSubmitting }) => (
          <Form>
            <Grid
              container
              direction="column"
              width="15rem"
              gap={1}
            >
              <Grid item>
                <FormikPasswordField
                  name="password"
                  label="Password"
                />
              </Grid>
              <Grid item alignSelf="flex-end">
                <ScaleButton
                  type="submit"
                  size="small"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  Generate API key
                </ScaleButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ApiKeyForm;
