import { useKeycloak } from '@react-keycloak/web';
import {
  ScaleTelekomNavList,
  ScaleTelekomNavItem,
  ScaleTelekomNavFlyout,
  ScaleTelekomMegaMenu,
  ScaleTelekomMegaMenuColumn,
} from '@telekom/scale-components-react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import rbacCheck from '../../rbac/rbacCheck';

const MainNavigation = () => {
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  const { organization } = user;
  const roles = keycloak.idTokenParsed?.roles;
  const isActive = (path) => pathname.startsWith(path) || undefined;

  const canSeeProducts = rbacCheck(roles, '/products', organization) || !keycloak.authenticated;
  const canSeeOrganizations = rbacCheck(roles, '/organizations:VIEW_ALL');
  const canSeeOwnOrganization = rbacCheck(roles, '/organizations:VIEW_OWN_DETAILS');
  const canOrderCompute = rbacCheck(roles, '/products/compute/order', organization);
  const canOrderSafescarfInstance = rbacCheck(roles, '/products/safescarf/order', organization);
  const canViewSafescarfInstance = rbacCheck(roles, '/products/safescarf', organization);
  const canViewDemands = rbacCheck(roles, '/demand-management', organization);
  const canSeeReports = rbacCheck(roles, '/reports');
  const canSeeMonitoring = rbacCheck(roles, '/monitoring', organization);

  return (
    <ScaleTelekomNavList
      slot="main-nav"
      variant="main-nav"
      aria-label="Main Navigation Links"
      role="menu"
    >
      <ScaleTelekomNavItem aria-label="Documentation" active={isActive('/docs')}>
        <Link to={`/docs/${import.meta.env.VITE_CONFLUENCE_HOMEPAGE}`}>Documentation</Link>
      </ScaleTelekomNavItem>

      {canSeeProducts && (
        <ScaleTelekomNavItem aria-label="Products" variant="main-nav" active={isActive('/products')}>
          <button type="button" aria-current aria-haspopup role="menuitem">Products</button>
          <ScaleTelekomNavFlyout hover className="headerFlyOutMenu">
            <ScaleTelekomMegaMenu>
              <ScaleTelekomMegaMenuColumn>
                <span slot="heading">Compute</span>
                <ul>
                  {keycloak.authenticated && (<li><Link to="/products/compute">My products</Link></li>)}
                  <li><Link to="/products/compute/specification">Product Specification</Link></li>
                  {canOrderCompute && (<li><Link to="/products/compute/order">Order</Link></li>)}
                  {keycloak.authenticated && (<li><Link to="/products/compute/free-trial">Free Trial</Link></li>)}
                </ul>
              </ScaleTelekomMegaMenuColumn>

              <ScaleTelekomMegaMenuColumn>
                <span slot="heading">SafeSCARF</span>
                <ul>
                  {keycloak.authenticated && canViewSafescarfInstance && (<li><Link to="/products/safescarf">My instance</Link></li>)}
                  <li><Link to="/products/safescarf/specification">Product Specification</Link></li>
                  {canOrderSafescarfInstance && (<li><Link to="/products/safescarf/order">Order</Link></li>)}
                </ul>
              </ScaleTelekomMegaMenuColumn>

              <ScaleTelekomMegaMenuColumn>
                <span slot="heading">CaaSonOpenstack</span>
                <ul>
                  {keycloak.authenticated && (<li><Link to="/products/caas-on-openstack">My products</Link></li>)}
                  <li><Link to="/products/caas-on-openstack/specification">Product Specification</Link></li>
                </ul>
              </ScaleTelekomMegaMenuColumn>

              <ScaleTelekomMegaMenuColumn hidden={!canSeeMonitoring}>
                <span slot="heading">Monitoring</span>
                <ul>
                  {keycloak.authenticated && (<li><Link to="/monitoring-dashboards">My dashboards</Link></li>)}
                </ul>
              </ScaleTelekomMegaMenuColumn>
            </ScaleTelekomMegaMenu>
          </ScaleTelekomNavFlyout>
        </ScaleTelekomNavItem>
      )}

      {canSeeOrganizations && (
        <ScaleTelekomNavItem aria-label="Organizations" active={isActive('/organizations')}>
          <Link to="/organizations">Organizations</Link>
        </ScaleTelekomNavItem>
      )}

      {canSeeOwnOrganization && (
        <ScaleTelekomNavItem variant="meta-nav" aria-label="Organization" active={isActive('/organizations')}>
          <Link to={`/organizations/${organization._id}`}>Organization</Link>
        </ScaleTelekomNavItem>
      )}

      {canSeeReports && (
        <ScaleTelekomNavItem aria-label="Reports" active={isActive('/reports')}>
          <Link to="/reports/tenants">Reports</Link>
        </ScaleTelekomNavItem>
      )}

      {canViewDemands && (
        <ScaleTelekomNavItem aria-label="Demands" active={isActive('/demand-management')}>
          <Link to="/demand-management">Demands</Link>
        </ScaleTelekomNavItem>
      )}
    </ScaleTelekomNavList>
  );
};

export default MainNavigation;
