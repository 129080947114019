import PropTypes from 'prop-types';
import { ScaleButton, ScaleIconActionCopyPaste } from '@telekom/scale-components-react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

const CopyButton = ({ data, onSubmit }) => {
  const copyToClipboard = useCopyToClipboard();

  return (
    <ScaleButton
      slot="action"
      onClick={() => {
        copyToClipboard(data);
        onSubmit();
      }}
    >
      Copy
      <ScaleIconActionCopyPaste />
    </ScaleButton>
  );
};

CopyButton.propTypes = {
  data: PropTypes.string,
  onSubmit: PropTypes.func,
};

CopyButton.defaultProps = {
  data: '',
  onSubmit: () => {},
};

export default CopyButton;
