import { TextField as TextFieldMUI } from '@mui/material';
import { PropTypes } from 'prop-types';

export const TextField = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  autoFocus,
  disabled,
  helperText,
  InputProps,
  multiline,
  rows,
}) => (
  <TextFieldMUI
    type="text"
    id={id || name}
    name={name}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    label={label}
    placeholder={placeholder}
    autoFocus={autoFocus}
    disabled={disabled}
    helperText={helperText}
    InputProps={InputProps}
    multiline={multiline}
    rows={rows}
  />
);

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.node,
    startAdornment: PropTypes.node,
    readOnly: PropTypes.bool,
  }),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

TextField.defaultProps = {
  id: '',
  onChange: () => {},
  onBlur: () => {},
  placeholder: '',
  autoFocus: false,
  disabled: false,
  helperText: '',
  InputProps: {},
  multiline: false,
  rows: 1,
};
