import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Button,
  CardActions,
} from '@mui/material';

import useAppContext from '../../hooks/useAppContext';

import tdiLogo from './tdiLogo_resized.png';
import juiceLogo from './juice_logo.png';
import juiceLogoDark from './juice_logo_white.svg';
import safescarf from './safescarf_logo.png';
import compute from './compute_logo_resized.png';
import caasWhiteTheme from './caas_white_theme.png';
import caasDarkTheme from './caas_dark_theme.png';


const Home = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { theme } = useAppContext();

  const products = [
    {
      title: intl.formatMessage({ id: 'juiceTitle' }),
      subheader: 'JUICE',
      image: theme === 'dark' ? juiceLogoDark : juiceLogo,
      description: intl.formatMessage({ id: 'juiceDescription' }),
      learnMore: () => { window.location.href = 'https://wiki.telekom.de/display/JUIC'; },
    },
    {
      title: intl.formatMessage({ id: 'safescarfTitle' }),
      subheader: 'SafeScarf',
      image: safescarf,
      description: intl.formatMessage({ id: 'safescarfDescription' }),
      learnMore: () => navigate('/docs/SafeSCARF%20product'),
    },
    {
      title: intl.formatMessage({ id: 'computeTitle' }),
      subheader: 'PAAS',
      image: compute,
      description: intl.formatMessage({ id: 'computeDescription' }),
      learnMore: () => navigate('/docs/Compute'),
    },
    {
      title: intl.formatMessage({ id: 'caasTitle' }),
      subheader: 'Caas (Coming soon)',
      image: theme === 'dark' ? caasDarkTheme : caasWhiteTheme,
      description: intl.formatMessage({ id: 'caasDescription' }),
      learnMore: () => { window.location.href = 'https://wiki.telekom.de/display/CITTEST/CaaS+White+Paper#CaaSWhitePaper-WhatisCITCaaS'; },
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="center"
      >
        <Grid item lg={3} xl={3}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={tdiLogo} alt="tdi" style={{ objectFit: 'contain' }} />
          </div>
          <Typography color="primary" variant="h4" align="center">
            {intl.formatMessage({ id: 'title' })}
          </Typography>
        </Grid>
        <Grid item lg={9} xl={9}>
          <div>
            <Typography variant="h5">
              {intl.formatMessage({ id: 'iaasDescription' })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: 'iaasSubDescription' })}
            </Typography>
            <Typography variant="h5">
              {intl.formatMessage({ id: 'iaasDescription1' })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: 'iaasSubDescription1' })}
            </Typography>
            <Typography variant="h5">
              {intl.formatMessage({ id: 'iaasDescription2' })}
            </Typography>
            <Typography component="span">
              {intl.formatMessage({ id: 'iaasSubDescription2' })}
            </Typography>
            <Link to="/contact-us">
              <Typography component="span">
                Contact us
              </Typography>
            </Link>
            <Typography component="span">
              {intl.formatMessage({ id: 'iaasSubDescription3' })}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        style={{ marginTop: '1rem' }}
      >
        {products.map((product) => (
          <Grid key={product.title} item lg={4} xl={3} md={6} style={{ display: 'flex' }}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardHeader
                title={product.title}
                subheader={product.subheader}
              />
              <CardMedia
                component="img"
                height="250"
                image={product.image}
                alt="Juice Logo"
                style={{ objectFit: 'contain' }}
              />
              <CardContent sx={{ flex: 1 }}>
                <Typography>
                  {product.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'end' }}>
                <Button size="small" onClick={product.learnMore}>Learn More</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Home;
