import {
  useEffect,
  useState,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useOutletContext } from 'react-router-dom';
import {
  Formik,
  Form,
  useFormikContext,
} from 'formik';
import { Grid } from '@mui/material';
import { ScaleButton } from '@telekom/scale-components-react';
import * as Yup from 'yup';
import validator from 'validator';

import Provision from '../../../api/provision';
import Auth from '../../../api/auth';
import useApiCall from '../../../hooks/useApiCall';
import { FormikPasswordField, FormikSelect } from '../../../components/Formik';

const CloudField = () => {
  const { setFieldValue } = useFormikContext();
  const [products, setProducts] = useState([]);
  const [virtualClouds, setVirtualClouds] = useOutletContext();
  const userData = useSelector((state) => state.user);
  const fetchProducts = useApiCall(Auth.fetchV2Products);
  const getVirtualCloudsCall = useApiCall(Provision.fetchVirtualClouds);

  const menuItems = virtualClouds?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    const getProducts = async () => {
      const [data] = await fetchProducts({ _id: userData.productIds });
      if (data) {
        setProducts(data.filter((product) => userData.productIds.includes(product._id)));
      }
    };

    if (virtualClouds === undefined) {
      getProducts();
    }
  }, []);

  useEffect(() => {
    const getVirtualClouds = async (juiceGroups) => {
      const [data] = await getVirtualCloudsCall({ juiceGroups });
      if (data?.length > 0) {
        setVirtualClouds(
          data.map((virtualCloud) => ({
            id: virtualCloud.cloud.name,
            name: virtualCloud.cloud.name,
            juiceGroup: virtualCloud.juice_group.name,
          })),
        );
      }
    };

    if (products.length) {
      const juiceGroups = products.map((product) => product.details.juiceGroup);
      getVirtualClouds(juiceGroups);
    }
  }, [products]);

  return (
    <FormikSelect
      name="cloudId"
      label="Cloud"
      menuItems={menuItems}
      onChange={(e) => {
        setFieldValue('cloudId', e.target.value);
        const selectedCloud = virtualClouds.find((vc) => vc.id === e.target.value);
        setFieldValue('juiceGroup', selectedCloud.juiceGroup);
      }}
    />
  );
};

const CloudPasswordReset = () => {
  const intl = useIntl();
  const formRef = useRef(null);
  const updateCloudPassword = useApiCall(Provision.updateCloudPassword, 'cloudPasswordUpdate');

  const initialValues = {
    juiceGroup: '',
    cloudId: '',
    newPasswordCloud: '',
    newPasswordConfirmCloud: '',
  };

  const validationSchema = Yup.object().shape({
    juiceGroup: Yup.string().required('Required'),
    cloudId: Yup.string().required('Required'),
    newPasswordCloud: Yup.string()
      .test(
        'Strong password',
        intl.formatMessage({ id: 'ERR_PASSWORD_INVALID' }),
        (value) => (value ? validator.isStrongPassword(value, { minLength: 10 }) : true),
      ).required('Required'),
    newPasswordConfirmCloud: Yup.string()
      .oneOf([Yup.ref('newPasswordCloud'), null], intl.formatMessage({ id: 'userPasswordMismatch' }))
      .required('Required'),
  });

  const changeCloudPassword = async (formData, { resetForm }) => {
    const { juiceGroup, cloudId, newPasswordCloud } = formData;
    const [data] = await updateCloudPassword(juiceGroup, cloudId, newPasswordCloud);
    await Provision.updateCloudPassword(juiceGroup, cloudId, newPasswordCloud);
    if (data) resetForm();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={changeCloudPassword}
    >
      {({
        dirty,
        isValid,
        isSubmitting,
      }) => (
        <Form>
          <Grid
            container
            direction="column"
            width="15rem"
            gap={1}
          >
            <Grid item>
              <CloudField />
            </Grid>
            <Grid item>
              <FormikPasswordField
                name="newPasswordCloud"
                label="New password"
              />
            </Grid>
            <Grid item>
              <FormikPasswordField
                name="newPasswordConfirmCloud"
                label="Confirm new password"
              />
            </Grid>
            <Grid item alignSelf="flex-end">
              <ScaleButton
                type="submit"
                size="small"
                disabled={!dirty || (!isValid || isSubmitting)}
              >
                Change Cloud Password
              </ScaleButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CloudPasswordReset;
