import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import rbacCheck from '../../../../../rbac/rbacCheck';

import Overview from './Overview';
import JuiceGroupContacts from '../../../../Reports/JuiceGroupContacts';

import productDetailsContent from './ProductDetailsContent';

import useApiCall from '../../../../../hooks/useApiCall';
import Auth from '../../../../../api/auth';


const ProductDetails = () => {
  const getProductCall = useApiCall(Auth.fetchV2ProductById);
  const { productId } = useParams();
  const userData = useSelector((state) => state.user);

  const [product, setProduct] = useState({});
  const [activeResources, setActiveResources] = useState(false);

  const canViewContacts = rbacCheck(userData.roles, '/products:CONTACTS_VIEW');
  const canSeeOrganizations = rbacCheck(userData.roles, '/organizations:VIEW_ALL');

  async function getProduct() {
    const [data] = await getProductCall(productId, true);
    if (data) setProduct(data);
  }

  useEffect(() => {
    getProduct();
  }, []);

  const renderContent = () => {
    const components = productDetailsContent[product.type] || [];
    return components.map(
      (content) => {
        const { Component } = content;
        return (
          <Component
            key={content.name}
            product={product}
            setActiveResources={setActiveResources}
          />
        );
      },
    );
  };

  return (
    <Grid
      className="content-with-header"
      container
      direction="column"
      rowGap={4}
    >
      <Grid item alignSelf="flex-start">
        <Breadcrumbs key={product._id} separator=" > ">
          {canSeeOrganizations
            ? <Link to="/organizations">organizations</Link>
            : <Link to={`/organizations/${product.organizationId}`}>organizations</Link>}
          <Link to={`/organizations/${product.organizationId}`}>{product.organization?.enterpriseName}</Link>
          <Link to={`/organizations/${product.organizationId}/projects`}>projects</Link>
          <Link to={`/projects/${product.project?._id}`}>{product.project?.name}</Link>
          <Link to={`/projects/${product.project?._id}`}>products</Link>
          <Typography>{product.name}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Container maxWidth="lg">
          <div style={{ marginBottom: '2rem' }}>
            <Overview
              product={product}
              activeResources={activeResources}
              onChange={() => getProduct()}
            />
          </div>
          {canViewContacts && product.details?.juiceGroup && (
          <JuiceGroupContacts juiceGroup={product.details?.juiceGroup} />
          )}
          {renderContent()}
        </Container>
      </Grid>
    </Grid>
  );
};

export default ProductDetails;
