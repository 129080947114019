import {
  useField,
  useFormikContext,
} from 'formik';
import PropTypes from 'prop-types';
import { TextField } from '../../InputFields';

export const FormikTextField = ({
  name,
  label,
  placeholder,
  autoFocus,
  disabled,
  onChange: propOnChange,
  InputProps,
}) => {
  const [field, meta, helpers] = useField(name);
  const { values } = useFormikContext();
  const isDisabled = typeof disabled === 'string' ? !values[disabled] : disabled;

  const handleChange = (event) => {
    helpers.setValue(event.target.value);
    if (propOnChange) {
      propOnChange(event);
    }
  };

  return (
    <TextField
      id={field.name}
      name={field.name}
      value={field.value}
      onChange={handleChange}
      onBlur={field.onBlur}
      label={label}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={isDisabled}
      helperText={meta.error !== undefined ? meta.error : ''}
      InputProps={InputProps}
    />
  );
};

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.node,
    startAdornment: PropTypes.node,
    readOnly: PropTypes.bool,
  }),
};

FormikTextField.defaultProps = {
  placeholder: '',
  autoFocus: false,
  disabled: false,
  onChange: null,
  InputProps: {},
};
