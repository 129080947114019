import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import {
  ScaleCard, ScaleIconAlertInformation,
  ScaleTooltip,
} from '@telekom/scale-components-react';
import { Breadcrumbs, Typography } from '@mui/material';
import {
  Link, useOutletContext, useParams, useLocation,
} from 'react-router-dom';

import DateTimeFilter from './DateTimeFilter';
import Filter from './Filters';
import Stat from './Stat';
import BarChartComponent from './BarChart';
import TimeSeries from './TimeSeries';
import GaugeComponent from './Gauge';
import MonitoringTable from './Table';
import GeoMap from './GeoMap';

import Page404 from '../../../Error/Page404';

const MonitoringComponents = ({ panelInfo, dateRange, filterParams }) => {
  if (panelInfo.type === 'text') {
    return (
      <Markdown>{panelInfo.content}</Markdown>
    );
  }
  if (panelInfo.type === 'stat') {
    return (
      <Stat
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: 700, fontSize: '20rem' }}
        filterParams={filterParams}
      />
    );
  }
  if (panelInfo.type === 'barchart') {
    return (
      <BarChartComponent
        panelInfo={panelInfo}
        dateRange={dateRange}
        height={700}
        filterParams={filterParams}
      />
    );
  }
  if (panelInfo.type === 'timeseries') {
    return (
      <TimeSeries
        panelInfo={panelInfo}
        dateRange={dateRange}
        styles={{ height: 1000 }}
        filterParams={filterParams}
      />
    );
  }
  if (panelInfo.type === 'gauge') {
    return (
      <GaugeComponent
        panelInfo={panelInfo}
        dateRange={dateRange}
        height={700}
        filterParams={filterParams}
      />
    );
  }
  if (panelInfo.type === 'table') {
    return (
      <MonitoringTable panelInfo={panelInfo} dateRange={dateRange} styles={{ height: '60rem' }} filterParams={filterParams} />
    );
  }

  if (panelInfo.type === 'geomap') {
    return (
      <GeoMap
        panelInfo={panelInfo}
        dateRange={dateRange}
        mapZoom={5}
        filterParams={filterParams}
      />
    );
  }
  return null;
};

MonitoringComponents.propTypes = {
  panelInfo: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
  filterParams: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ])).isRequired,
};

const PanelDetails = () => {
  const location = useLocation();
  const { panelId, dashboardId } = useParams();
  const [panelData, setPanelData] = useState({});
  const context = useOutletContext();
  const [dashboardData] = context;
  const templates = dashboardData.templating.filter((template) => template.hide === 0);

  const searchParams = new URLSearchParams(location.search);

  const [dateRange, setDateRange] = useState({
    from: searchParams.get('from'),
    to: searchParams.get('to'),
  });

  const [filterParams, setFilterParams] = useState(templates.reduce((acc, template) => {
    acc[template.name] = searchParams.get(template.name) || template.current.value;
    return acc;
  }, {}));

  const getPanelData = async () => {
    const panelRow = dashboardData.rows.find(
      (row) => row.panels.find((p) => p.id.toString() === panelId),
    );

    if (!panelRow) {
      setPanelData(panelRow);
    } else {
      const panel = panelRow.panels.find((p) => p.id.toString() === panelId);
      setPanelData(panel);
    }
  };

  useEffect(() => {
    getPanelData();
  }, [searchParams]);

  const getDashboardUrl = () => {
    let newUrl = `/monitoring-dashboards/${dashboardId}?from=${dateRange.from}&to=${dateRange.to}`;
    templates.forEach((template) => {
      const value = searchParams.get(template.name) || template.current.value;
      newUrl += `&${template.name}=${value}`;
    });
    return newUrl;
  };

  return panelData ? (
    <div style={{ margin: '1rem -10%' }}>
      <Breadcrumbs separator=" > ">
        <Link to={getDashboardUrl()}>{dashboardData.title}</Link>
        <Typography>{panelData.title}</Typography>
      </Breadcrumbs>
      <DateTimeFilter dateRange={dateRange} setDateRange={setDateRange} />
      {templates && templates.length > 0
      && <Filter setFilterParams={setFilterParams} templates={templates} />}
      <ScaleCard>
        <div style={{ height: '50rem' }}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h5" noWrap>{panelData.title}</Typography>
            <ScaleTooltip content={panelData.description}>
              <ScaleIconAlertInformation size={20} style={{ marginTop: '0.2rem', marginLeft: '0.5rem' }} />
            </ScaleTooltip>
          </div>
          <MonitoringComponents
            panelInfo={panelData}
            dateRange={dateRange}
            filterParams={filterParams}
          />
        </div>
      </ScaleCard>
    </div>
  ) : <Page404 />;
};

export default PanelDetails;
