import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@mui/material';
import { TextField } from '../../../../../components/InputFields/TextField';
import CopyButton from '../../../../../components/CopyButton/index';
import { CopyField } from '../../../../../components/InputFields/CopyField';

const MachineAccountsApiKeyForm = ({ machineAccountName, machineAccountToken }) => {
  const intl = useIntl();

  return (
    <>
      <Typography gutterBottom>
        {intl.formatMessage({ id: 'SafescarfMachineAccountCreated' })}
      </Typography>
      <Grid
        container
        direction="column"
        gap={1}
      >
        <Grid item>
          <CopyField
            label="Username"
            name="username"
            value={machineAccountName}
          />
        </Grid>
        <Grid item>
          <Typography fontWeight="bold">
            {intl.formatMessage({ id: 'APIkeyGenerated' })}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage({ id: 'APIkeyCopy' })}
          </Typography>
          <Grid
            container
            alignItems="center"
            gap={1}
          >
            <Grid item xs>
              <TextField
                label="API key"
                name="APIkey"
                value={machineAccountToken}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item>
              <CopyButton data={machineAccountToken} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

MachineAccountsApiKeyForm.propTypes = {
  machineAccountName: PropTypes.string,
  machineAccountToken: PropTypes.string,
};
MachineAccountsApiKeyForm.defaultProps = {
  machineAccountName: '',
  machineAccountToken: '',
};

export default MachineAccountsApiKeyForm;
