import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Grid, Typography } from '@mui/material';
import { ScaleButton } from '@telekom/scale-components-react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikDateTimeField } from '../../../../components/Formik/index';

const nowDate = new Date().toISOString().slice(0, 16);
const DateTimeFilter = ({ dateRange, setDateRange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        fromDate: dateRange.from,
        toDate: dateRange.to,
      }}
      validationSchema={Yup.object().shape({
        fromDate: Yup.date().required('Required')
          .max(Yup.ref('toDate'), 'From date must be before To date'),
        toDate: Yup.date().max(nowDate).required('Required')
          .min(Yup.ref('fromDate'), 'To date must be after From date'),
      })}
      onSubmit={(data) => {
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set('from', data.fromDate);
        newSearchParams.set('to', data.toDate);
        const newUrl = `${location.pathname}?${newSearchParams.toString()}`;
        navigate(newUrl, { replace: true });
        setDateRange({ from: data.fromDate, to: data.toDate });
      }}
    >
      {() => (
        <Form style={{ margin: '1rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={0.5} display="flex" alignItems="center">
              <Typography>Time Range: </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormikDateTimeField
                name="fromDate"
                label="From"
                inputProps={{ max: nowDate }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormikDateTimeField
                name="toDate"
                label="To"
                inputProps={{ max: nowDate }}
              />
            </Grid>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <ScaleButton type="submit">
                Submit
              </ScaleButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

DateTimeFilter.propTypes = {
  setDateRange: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }).isRequired,
};

export default DateTimeFilter;

